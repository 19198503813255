<template>
  <div class="companyProfile">
    <CompanyProfileBanner :index="'1'" :imageUrl="imageUrl"></CompanyProfileBanner>
    <BreadcrumbAndSubtag
      :tabList="tabList"
      :activeNowPath="activeNowPath"
      @changeActiveTabFun="changeActiveTabFun"
    ></BreadcrumbAndSubtag>
    <template v-if="activeNowPath === '2-1'">
      <div class="intro">
        <h2>天津求实智源科技有限公司</h2>
        <p>Tianjin Qiushi Zhiyuan Technology Co., LTD</p>
        <div class="contents">
          <div class="introImg">
            <img class="firmImg1" :src="aboutImages[0]" />
            <img class="firmImg2" :src="aboutImages[1]" />
            <em></em>
            <p>INTRODUCTION</p>
            <span></span>
          </div>
          <div class="introText">
            <p v-for="(text, index) in aboutText" :key="index">
              {{ text }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="algorithm">
        <pageSubtag
          :title="'核心算法'"
          :color="'#004AAE'"
          :titleEn="'CORE ALGORITHM'"
          :sketch="algorithmImagesText.join(' ')"
          style="margin: 0 0 2em 0;"
        ></pageSubtag>
        <div class="contentDiv">
          <div class="contents">
            <div class="algorithmText">
              <p v-for="(text, index) in algorithmText" :key="index">
                {{ text }}
              </p>
            </div>
            <div class="algorithmImg" >
              <div  v-for="images in algorithmImages" :key="images.title" class="algorithmImgItem" >
                <div class="itemImg">
                  <img :src="images.url">
                </div>
                <h4>{{ images.title }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team">
        <pageSubtag
          :title="'专业团队'"
          :color="'#009EFD'"
          :titleEn="'PROFESSIONAL TEAM'"
          :sketch="teamImagesText.join(' ')"
        ></pageSubtag>
        <div class="contentDiv">
          <div class="contents">
            <div class="teamImg">
              <img :src="teamImage" />
            </div>
            <div class="teamText">
              <div class="line"></div>
              <div class="texts">
                <p v-for="(text, index) in teamText" :key="index">
                  {{ text }}
                </p>
              </div>
              <div class="labelDiv">
                <div v-for="title in teamImagesText" :key="title">
                  <strong>{{ title }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="honor">
        <pageSubtag
          :title="'企业荣誉'"
          :color="'#00A99D'"
          :titleEn="'ENTERPRISE HONOR'"
          :sketch="honorImagesText.join(' ')"
        ></pageSubtag>
        <div class="contentDiv">
          <div class="contents">
            <div class="overview">
              <p v-for="(text, index) in honorText" :key="index">
                {{ text }}
              </p>
            </div>
            <div class="yearsBox">
              <el-row>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="title">{{ inventionPatentNum }}</div>
                  <p>发明专利</p>
                </el-col>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="title">{{ copyrights }}</div>
                  <p>软件著作权</p>
                </el-col>
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <div class="title">{{ (new Date()).getFullYear()-2014}}</div>
                  <p>开发经验</p>
                </el-col>

              </el-row>
            </div>
          </div>
        </div>
        <div class="honorDiv">
          <div class="contents">
            <h2>荣誉资质</h2>
            <div class="honorList">
              <vueSeamless
                v-if="honorImages.length >= 1"
                :data="honorImages"
                class="seamless-warp"
                :class-option="classOption"
              >
                <ul>
                  <li v-for="(image,index) in honorImages" :key="index">
                    <img :src="image" />
                  </li>
                </ul>
              </vueSeamless>
            </div>
          </div>
        </div>
      </div>
    </template>
    <footerMain></footerMain>
  </div>
</template>

<script>
import vueSeamless from 'vue-seamless-scroll'
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'
import pageSubtag from '@/components/pageContent/pageSubtag'
import footerMain from '@/components/footerMain'
import {mapGetters} from "vuex";


export default {
  name: "companyProfile",
  components: {
    vueSeamless,
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    pageSubtag,
    footerMain
  },
  data() {
    return {
      tabList: [
        {key: '2-1', name: '关于我们'},
        {key: '2-2', name: '我们的优势'},
      ],
      activeNowPath: '',
      copyrights: copyrights,
      inventionPatentNum: inventionPatentNum,
      imageUrl: '',
      aboutImages: [],
      aboutText: [],
      algorithmText: [],
      algorithmImages: [],
      algorithmImagesText: [],
      teamText: [],
      teamImage: '',
      teamImagesText: [],
      honorText: [],
      honorImagesText: [],
      honorImages: [],
    }
  },
  computed: {
    ...mapGetters(['secondaryRoutingIndex']),
    classOption() {
      return {
        step: 1, //滚动的速度
        limitMoveNum: this.honorImages.length, //开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, //鼠标悬停，true/false
        direction: 2, //滚动的方向，0向下 1向上 2向左 3向右
        waitTime: 1000 //单步运动停止的时间(默认值1000ms)
      }
    }
  },
  watch: {
    secondaryRoutingIndex: {
      handler(n,o) {
        const checkPath = (url) => {
          return [
            "2-1",
            "2-2",
          ].includes(url);
        }
        if(checkPath(n)) {
          this.activeNowPath = n
        } else {
          this.activeNowPath = '2-1'
          this.$store.commit('SET_CURRENT_ROUTE', '/companyProfile')
          this.$store.commit('SET_SECONDARY_ROUTING_INDEX', '2-1')
        }
      },
      immediate: true
    },
  },
  created() {
    this.activeNowPath = this.secondaryRoutingIndex === '' ? '2-1' : this.secondaryRoutingIndex
    this.loadResourceOfPageFun()
  },
  methods: {
    changeActiveTabFun(val) {
      this.activeNowPath = val
      this.$store.commit('SET_SECONDARY_ROUTING_INDEX', val)
    },
    loadResourceOfPageFun() {
      api.getResourceOfPage({pageId: 2, includeSub: 1}).then((res) => {
        if(res.code === 100) {
          const _data = res.data;
          _data.forEach(d => {
            if(d.pageId === 2) {
              this.imageUrl = resAddressPrefix+d.images[0].imageUrl
            } else if(d.pageId === 3){
              this.aboutText = d.pageText.split('&&')
              d.images.forEach(l => {
                if(l.locationType === 3) {
                  this.aboutImages.push(resAddressPrefix + l.imageUrl)
                }
              })
            } else if(d.pageId === 5) {
              this.algorithmText = d.pageText.split('&&')
              d.images.forEach(l => {
                if(l.locationType === 3) {
                  this.algorithmImages.push({
                    title: l.imageTitle,
                    url: resAddressPrefix + l.imageUrl,
                  })
                  this.algorithmImagesText.push(l.imageTitle)
                }
              })
            } else if(d.pageId === 6) {
              this.teamText = d.pageText.split('&&')
              d.images.forEach(l => {
                if(l.locationType === 2) {
                  this.teamImagesText.push(l.imageTitle)
                } else {
                  this.teamImage = resAddressPrefix + l.imageUrl
                }
              })
            } else if(d.pageId === 7) {
              this.honorText = d.pageText.split('&&')
              d.images.forEach(l => {
                if(l.locationType === 2) {
                  this.honorImagesText.push(l.imageTitle)
                }if(l.locationType === 3) {
                  this.honorImages.push(resAddressPrefix + l.imageUrl)
                }
              });
            }
          });
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.companyProfile{
  .intro{
    h2 {
      color: #004AAE;
      font-size: 2em;
      margin: 1.6em 0 0 0;
    }
    p {
      font-size: 14px;
      color: #666666;
      margin: 0.5em 0 1em 0;
    }
    .contents {
      width: 1200px;
      height: 440px;
      display: flex;
      margin: 2em auto;
      .introImg {
        flex: 1;
        position: relative;
        .firmImg1 {
          width: 500px;
          height: 390px;
          position: absolute;
          right: 20px;
        }
        .firmImg2 {
          position: absolute;
          height: 160px;
          left: 0;
          width: 160px;
          bottom: 10px;
        }
        em {
          width: 8px;
          height: 8px;
          background: #004AAE;
          position: absolute;
          left: 2px;
          top: 0px;
        }
        p {
          transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          position: absolute;
          left: -7%;
          top: 13%;
          font-size: 12px;
          color: #040404;
        }
        span {
          width: 1px;
          height: 120px;
          background: #999;
          position: absolute;
          left: 4px;
          top: 30%;
        }
      }
      .introText {
        flex: 1;
        padding-left: 20px;
        p {
          color: #555;
          line-height: 1.8;
          text-indent: 2em;
          font-size: 16px;
          text-align: left;
        }
      }
    }
  }
  .algorithm {
    .contentDiv {
      margin: auto;
      display: table;
      vertical-align: middle;
      .contents {
        width: 1200px;
        margin: 2em auto;
        display: table-cell;
        vertical-align: middle;
        .algorithmText,
        .algorithmImg {
          width: calc(50% - 40px);
          padding: 0 20px;
          display: inline-block;
          vertical-align: middle;
        }
        .algorithmText {
          p {
            color: #555;
            line-height: 1.8;
            text-indent: 2em;
            font-size: 16px;
            text-align: left;
          }
        }
        .algorithmImg {
          .algorithmImgItem {
            width: calc(50% - 1px);
            padding: 2em 0;
            display: inline-block;
            .itemImg {
              width: 110px;
              height: 110px;
              margin: auto;
              border-radius: 55px;
              overflow: hidden;
              cursor: pointer;
              img {
                position: relative;
                top: 0;
                transition: all .3s;
                &:hover {
                  top: -110px;
                }
              }
            }
            h4 {
              margin: 0.6em;
            }
            &:nth-child(1) {
              border-right: 1px solid #ECECEC;
              border-bottom: 1px solid #ECECEC;
            }
            &:nth-child(2) {
              border-bottom: 1px solid #ECECEC;
            }
            &:nth-child(3) {
              border-right: 1px solid #ECECEC;
            }
            &:nth-child(4) {
            }
          }
        }
      }
    }
  }
  .team {
    .contentDiv {
      margin: auto;
      display: table;
      vertical-align: middle;
      .contents {
        width: 1200px;
        padding: 2em 0;
        margin: 2em auto;
        display: table-cell;
        vertical-align: middle;
        .teamText,
        .teamImg {
          display: inline-block;
          vertical-align: middle;
        }
        .teamText {
          width: calc(100% - 540px);
          padding: 60px 60px 40px 60px;
          margin-left: -160px;
          background: #FFFFFF;
          box-shadow: 4px 6px 20px 4px rgba(0,0,0,0.2);
          .line {
            height: 0;
            width: 40px;
            border-top: 4px solid #009EFD;
          }
          .texts {
            p {
              color: #555;
              line-height: 1.8;
              text-indent: 2em;
              font-size: 16px;
              text-align: left;
            }
          }
          .labelDiv {
            display: flex;
            div {
              flex: 1;
              margin: auto;
              strong {
                color: #009EFD;
                font-size: 1.4em;
              }
            }
          }
        }
        .teamImg {
          width: 520px;
          height: 588px;
          padding: 0 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .honor {
    .contentDiv {
      margin: auto;
      display: table;
      vertical-align: middle;
      .contents {
        width: 1200px;
        padding: 2em 0;
        margin: 2em auto;
        display: table-cell;
        vertical-align: middle;
        .overview {
          text-align: left;
          text-indent: 2em;
        }
        .yearsBox {
          padding: 3em 0;
          ::v-deep .el-col {
            &:nth-child(1),
            &:nth-child(2) {
              .title {
                &:after {
                  content: "+";
                }
              }
            }
            &:nth-child(3) {
              .title {
                &:after {
                  content: "年";
                }
              }
            }
          }
          .title {
            color: #00A99D;
            font-size: 46px;
            font-weight: bold;
            position: relative;
            display: inline-block;
            &:after {
              display: inline-block;
              position: absolute;
              right: -20px;
              top: 10px;
              font-size: 14px;
            }
          }
          p {
            margin: 0;
            font-size: 16px;
          }
        }
      }
    }
    .honorDiv {
      margin: auto;
      display: table;
      vertical-align: middle;
      background: #F4F8F8;
      overflow: hidden;
      .contents {
        width: 1200px;
        padding: 2em 0;
        margin: 2em auto;
        display: table-cell;
        vertical-align: middle;
        overflow: hidden;
        .honorList {
          width: 1200px;
          height: 280px;
          padding: 2em 0 1em 0;
          overflow: hidden;
          ul {
            padding: 0;
            margin-top: 2px;
            //width: 1120px;
            //height: 100%;
            list-style: none;
            cursor: pointer;
            display: flex;
            li {
              //float: left;
              width: 180px;
              height: 240px;
              padding: 10px;
              margin: 10px;
              display: inline-block;
              border: 2px solid #00A99D;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>

