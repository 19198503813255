<template>
  <div class="pageSubtag" :style="{background: color}">
    <div class="subtagCore">
      <h4>{{ title }}</h4>
      <h6>{{ titleEn }}</h6>
      <div class="line"></div>
      <p>{{ sketch }}</p>
      <div class="overlay" :style="{background: color}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageSubtag",
  props: ['title', 'color', 'titleEn', 'sketch'],
}
</script>

<style scoped lang="scss">
.pageSubtag {
  width: 100%;
  margin: 2em 0;
  //background: #004AAE;
  display: table;
  vertical-align: middle;
  .subtagCore {
    width: 1200px;
    height: 160px;
    color: #FFFFFF;
    display: table-cell;
    vertical-align: middle;
    h4 {
      margin: 0;
      font-size: 24px;
      font-weight: 400;
      line-height: 33.6px;
      letter-spacing: 1px;
    }
    h6 {
      color: #E1E1E1;
      margin: 2px 0;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .line {
      width: 80px;
      height: 1px;
      margin: 6px auto 20px auto;
      background: #7ecaed;
    }
    p {
      margin: 0;
      font-size: 14px;
    }
    .overlay {
      width: 100%;
      height: 19px;
      margin-top: -19px;
      //background: #004AAE;
      opacity: 0.6;
    }
  }
}
</style>
